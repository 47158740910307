<template>
  <div>
    <Close />
    <section class="hero follow-section">
      <div class="hero-body">
        <div class="block">
          <h1
            class="is-size-3 has-text-weight-bold my-5"
            v-html="$t('notifications')"
          ></h1>
        </div>
        <div class="block" v-if="notifications.length">
          <div v-for="notification in notifications" :key="notification.key" class="has-background-light p-4">
            <div class="is-flex is-flex-align-center">
              <div class="mr-4 is-flex-grow-1 has-text-left">
                <div class="has-text-weight-bold is-size-5">
                  <fa icon="bell" class="mr-2 has-text-primary"></fa> {{ roomsNameMap[notification.room_id] }}
                </div>
              </div>
              <div>
                <button class="button is-small is-outlined is-danger" @click="removeNotification(notification.key)">Remover</button>
              </div>
            </div>
            <div class="has-text-left mt-2">
              <div>
                {{ $t("notify_when") }}
                <span class="has-text-primary has-text-weight-bold">{{ notification.min_available_seats }}</span>
                {{ $t('seats_are_available') }}
              </div>
              <div>
                {{ $t("send_message_to") }}
                <span class="has-text-primary has-text-weight-bold">{{ notification.phone }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="block has-text-small">
          {{ $t('no_notifications') }}.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ServiceBase from '@/services/ServiceBase'
import Close from '@/components/Close'

export default {
  components: {
    Close
  },
  beforeDestroy () {
    clearInterval(this.loadNotificationsInterval)
  },
  data () {
    return {
      roomsNameMap: {
        1: this.$t('rooms.1.name'),
        5: this.$t('rooms.2.name'),
        2: this.$t('rooms.3.name')
      }
    }
  },
  mounted () {
    this.loadNotifications()
    clearInterval(this.loadNotificationsInterval)
    this.loadNotificationsInterval = setInterval(() => {
      this.loadNotifications()
    }, 30000)
  },
  computed: {
    hasNotifications () {
      return Boolean(this.$store.state.notifications.length)
    },
    notifications () {
      return this.$store.state.notifications
    }
  },
  methods: {
    loadNotifications () {
      if (this.$store.state.session.token) {
        this.$store.dispatch('loadNotifications')
      }
    },
    async removeNotification (key) {
      await ServiceBase.deleteNotification(key)
      this.$router.push('/')
    }
  }
}
</script>
