<template>
  <router-link
    class="button is-outlined close-button"
    to="/"
  >
    {{ $t("close") }}
  </router-link>
</template>

<script>
export default {
  name: 'Close'
}
</script>
